@font-face {
	font-family: "Sofia Pro";
	font-weight: 300;
	src: local("Sofia Pro"),
		url(assets/fonts/SofiaProLight.ttf) format("truetype");
}

@font-face {
	font-family: "Sofia Pro";
	font-weight: 400;
	src: local("Sofia Pro"),
		url(assets/fonts/SofiaProRegular.ttf) format("truetype");
}

@font-face {
	font-family: "Sofia Pro";
	font-weight: 500;
	src: local("Sofia Pro"),
		url(assets/fonts/SofiaProMedium.ttf) format("truetype");
}

@font-face {
	font-family: "Sofia Pro";
	font-weight: 600;
	src: local("Sofia Pro"),
		url(assets/fonts/SofiaProSemiBold.ttf) format("truetype");
}

@font-face {
	font-family: "Sofia Pro";
	font-weight: 700;
	src: local("Sofia Pro"), url(assets/fonts/SofiaProBold.ttf) format("truetype");
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	font-family: "Sofia Pro";
}

#root {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-x: hidden;
}

@media only screen and (max-width: 1024px) {
	#root {
		height: 100%;
	}
}

@media only screen and (min-width: 800px) {
	#root {
		overflow: hidden; /*We need it because on small screens our login page has scroll bars.*/
	}
}

/*
	reach router adds separate div to each view with tabindex attribute.
	And we need to have all div flex to manage height properly
*/
#root > div[tabindex] {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}
